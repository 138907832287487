<template>
  <div>
    <b-modal
        v-model="showModal"
        :title="$t('gameservers.confirmation')"
        :ok-title="$t('gameservers.confirm')"
        :cancel-title="$t('gameservers.cancel')"
        @ok="closeTicket(ticket_data.ticket_id)"
    >
      {{ $t('gameservers.confirm_question') }}
    </b-modal>
    <div class="row">
      <div class="col">
        <p class="card-title-desc mb-1">{{ $t('tickets.subject') }}: {{ ticket_data.ticket_title }}</p>
        <p v-if="ticket_data.userproduct_id > 0" class="card-title-desc mb-1">
          {{ $t('tickets.machine') }}:
          [{{ ticket_data.userproduct_id }}] {{ ticket_data.userproduct_name }}
        </p>
        <p class="card-title-desc">
          {{ $t('tickets.status') }}: {{ $t(getTicketStatusText(ticket_data.ticket_status)) }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button v-show="ticket_data.ticket_status != 4"
                class="btn btn-sm btn-danger"
                style="position: absolute; right: 10px; top: -40px"
                @click="showModal = true">
          {{ $t('tickets.close_ticket') }}
        </button>
        <div class="card card-body">
          <simplebar ref="current" style="max-height: 450px">
            <div
                v-for="message in message_list"
                :key="message.id"
                :class="{ right: $store.state.session.uid == message.uid }"
                class="d-flex align-items-start border-bottom py-4 px-2">
              <div class="flex-grow-1">
                <h5 class="font-size-15 mb-1">
                  <template v-if="$store.state.session.uid != message.uid">{{ $t('tickets.support') }}</template>
                  <template v-else>{{ $store.state.session.email }}</template>
                  <small class="text-muted float-end">{{ message.date }}</small>
                </h5>
                <p class="text-muted" style="white-space: pre-wrap" v-html="wrapEmoji(message.message)"></p>
              </div>
            </div>
            <div v-if="ticket_data.ticket_status == 4"
                 class="d-flex align-items-start border-bottom py-4 px-2"
                 style="background-color: #f3f8fb">
              <div class="flex-grow-1">
                <h5 class="font-size-15 mb-1">
                  {{ $t('tickets.support') }}
                </h5>
                <p style="color: #5b73e8 !important">
                  {{ $t('tickets.closed_ticket_info') }}
                </p>
              </div>
            </div>
          </simplebar>
          <div v-show="ticket_data.ticket_status != 4" class="border rounded my-4">
            <div class="px-2 py-1 bg-light">
<!--              <vue-dropzone class="disabled" style="min-height: 75px; height: 75px" id="drop-zone" :options="dropzoneOptions"-->
<!--                            :useCustomSlot=true>-->
<!--                <button type="button" class="btn btn-sm btn-link text-dark text-decoration-none">-->
<!--                  <i class="uil uil-link"></i>-->
<!--                </button>-->
<!--              </vue-dropzone>-->
            </div>
            <button type="button"
                    style="position: absolute; right: 25px"
                    class="d-none d-md-block btn btn-sm btn-link text-dark text-decoration-none">
              <i @mousedown.prevent="toggleEmojiPicker" class="uil uil-smile"></i>
              <Picker style="position: absolute; z-index: 10000; right: 10px; bottom: 30px" v-show="showEmoji"
                      :data="emojiIndex" set="apple" @select="addEmoji"
                      :defaultSkin="1" :skin="1"
                      :showPreview="false" :showSearch="false" :showSkinTones="false" :infiniteScroll="false"
                      :emojiSize="22"/>
            </button>
            <textarea v-model="message"
                      ref="textarea"
                      rows="3"
                      class="form-control border-0 resize-none"
                      :placeholder="$t('tickets.message')"
            ></textarea>
          </div>
          <button
              v-if="!loading"
              v-show="ticket_data.ticket_status != 4"
              @click="sendTicketMessage(ticket_data.ticket_id)"
              class="btn btn-primary chat-send w-md waves-effect waves-light"
          >
            <span class="me-2">{{ $t('tickets.submit') }}</span>
            <i class="mdi mdi-send float-end"></i>
          </button>
          <button v-else v-show="ticket_data.ticket_status != 4" class="btn btn-primary chat-send w-md waves-effect waves-light" disabled><b-spinner small></b-spinner> {{$t('links.loading')}}...</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/modules/Axios";
import simplebar from "simplebar-vue";
import "emoji-mart-vue-fast/css/emoji-mart.css";
import {EmojiIndex, Picker} from "emoji-mart-vue-fast";
import emoji from "emoji-mart-vue-fast/data/apple.json";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

const COLONS_REGEX = new RegExp(
    '([^:]+)?(:[a-zA-Z0-9-_+]+:(:skin-tone-[2-6]:)?)',
    'g'
)
let emojiIndex = new EmojiIndex(emoji)

export default {
  name: "ticket",
  components: {Picker, simplebar, vueDropzone: vue2Dropzone,},
  data() {
    return {
      showModal: false,
      emojiIndex: emojiIndex,
      showEmoji: false,
      ticket_data: '',
      message: '',
      message_list: [],
      interval: undefined,
      loading: false,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 50,
        maxFilesize: 0.5,
      },
    }
  },
  mounted() {
    if (this.$store.state.tickets.ticket_id) {
      this.getTicketData(this.$store.state.tickets.ticket_id)

      // Очищаем интервал
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = undefined
      }

      this.interval = setInterval(() => this.getTicketData(this.$store.state.tickets.ticket_id), 60000)
    }
  },
  destroyed () {
    // Очищаем интервал
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = undefined
    }
  },
  methods: {
    async closeTicket(ticket_id) {
      try {
        const response = await axios.post("Ticket.php", {
          mode: "close_ticket",
          tid: ticket_id
        });

        this.ticket_data.ticket_status = 4
        setTimeout(() => this.scrollChatToBottom(), 100);
      } catch (error) {
        console.error(error.message);
      }
    },
    addEmoji(emoji) {
      let message
      if (emoji.skin) {
        let colons = emoji.colons.split(':')
        message = ':' + colons[1] + ':'
      } else {
        message = emoji.colons
      }
      const textarea = this.$refs.textarea
      const cursorPosition = textarea.selectionEnd
      const start = this.message.substring(0, textarea.selectionStart)
      const end = this.message.substring(textarea.selectionStart)
      this.message = start + message + end
      textarea.focus()
      this.$nextTick(() => {
        textarea.selectionEnd = cursorPosition + message.length
      })
      this.showEmoji = false
    },
    toggleEmojiPicker() {
      this.showEmoji = !this.showEmoji
    },
    wrapEmoji(text) {
      let context = this
      return text.replace(COLONS_REGEX, function (match, p1, p2) {
        const before = p1 || ''
        if (before.endsWith('alt="') || before.endsWith('data-text="')) {
          return match
        }
        let emoji = context.emojiIndex.findEmoji(p2)
        if (!emoji) {
          return match
        }
        return before + context.emojiToHtml(emoji)
      })
    },
    emojiToHtml(emoji) {
      let style = `background-position: ${emoji.getPosition()}; width: 22px; height: 22px;`
      return `<div title="${emoji.colons}" class="emoji-set-apple emoji-type-image emoji" style="${style}"></div>`
    },
    getTicketStatusText(status) {
      switch (parseInt(status)) {
        case 1:
          return "tickets.status_ticket_open";
        case 2:
          return "tickets.status_ticket_consideration";
        case 3:
          return "tickets.status_ticket_in_work";
        case 4:
          return "tickets.status_ticket_closed";
        case 5:
          return "tickets.status_ticket_solved";
      }
    },
    /**
     * Получение информации о тикете
     */
    async getTicketData(ticket_id) {
      try {
        // Отправка запроса на сервер
        const response = await axios.post("Ticket.php", {
          mode: "get_ticket",
          tid: ticket_id
        });

        // Сохраняем данные тикета
        this.ticket_data = response.data;

        // Вызываем метод получения списка сообщений тикета
        this.getTicketMessages(ticket_id);
      } catch (error) {
        console.error(error.message);
      }
    },
    /**
     * Получение сообщений тикета
     */
    async getTicketMessages(ticket_id) {
      try {
        // Отправка запроса на сервер
        const response = await axios.post("Ticket.php", {
          mode: "list_ticket_messages",
          tid: ticket_id
        });

        // Очищаем старый список сообщений
        this.message_list = [];

        // Сохраняем список сообщений
        response.data.forEach(message => {
          this.message_list.push({
            id: message.id,
            message: message.message,
            date: message.date,
            uid: message.uid
          });
        });

        setTimeout(() => this.scrollChatToBottom(), 250);
      } catch (error) {
        console.error(error.message);
      }
    },
    /**
     * Отправка сообщения в тикет
     */
    async sendTicketMessage(ticket_id) {
      this.loading = true
      try {
        const response = await axios.post("Ticket.php", {
          mode: "send_message",
          tid: ticket_id,
          message: this.message
        });

        this.message = "";
        this.getTicketMessages(ticket_id);
        this.loading = false
      } catch (error) {
        console.error(error.message);
        this.loading = false
      }
    },
    /**
     * Скролит чат до нижней границы
     */
    scrollChatToBottom() {
      // Находим элемент в DOM дереве
      const chatHTMLElement = this.$refs.current.SimpleBar.getScrollElement();

      // Если элемент не найден - завершаем метод
      if (typeof chatHTMLElement === "undefined" || !chatHTMLElement) return;

      chatHTMLElement.scrollTo(0, chatHTMLElement.scrollHeight);
    },
  }
}
</script>

<style lang="sass">
.dropzone .dz-message
  margin: unset !important

.dropzone .dz-preview
  min-height: 50px !important

.vue-dropzone > .dz-preview .dz-image
  width: 50px !important
  height: 50px !important

</style>
